<template>
  <div id="kt_wizard_v4" class="kt-wizard-v4" data-ktwizard-state="step-first">
    <!--begin: Form Wizard Nav -->
    <div class="kt-wizard-v4__nav">
      <div class="kt-wizard-v4__nav-items kt-wizard-v4__nav-items--clickable">
        <!--doc: Replace A tag with SPAN tag to disable the step link click -->
        <div
          class="kt-wizard-v4__nav-item"
          data-ktwizard-state="current"
          data-ktwizard-type="step"
        >
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              1
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                Add Account
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                Create Custom Account
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              2
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                Your Address
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                Setup Your Address
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              3
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                Make Payment
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                Add Payment Options
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              4
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                Completed
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                Review and Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end: Form Wizard Nav -->

    <div class="kt-portlet">
      <div class="kt-portlet__body kt-portlet__body--fit">
        <div class="kt-grid">
          <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
            <!--begin: Form Wizard Form-->
            <form id="kt_form" class="kt-form">
              <!--begin: Form Wizard Step 1-->
              <div
                class="kt-wizard-v4__content"
                data-ktwizard-state="current"
                data-ktwizard-type="step-content"
              >
                <div class="kt-heading kt-heading--md">
                  Enter your Account Details
                </div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <div class="form-group">
                      <label>First Name</label>
                      <input
                        class="form-control"
                        name="fname"
                        placeholder="First Name"
                        type="text"
                        value="John"
                      />
                      <span class="form-text text-muted"
                        >Please enter your first name.</span
                      >
                    </div>
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        class="form-control"
                        name="lname"
                        placeholder="Last Name"
                        type="text"
                        value="Wick"
                      />
                      <span class="form-text text-muted"
                        >Please enter your last name.</span
                      >
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Phone</label>
                          <input
                            class="form-control"
                            name="phone"
                            placeholder="phone"
                            type="tel"
                            value="+61412345678"
                          />
                          <span class="form-text text-muted"
                            >Please enter your phone number.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            class="form-control"
                            name="email"
                            placeholder="Email"
                            type="email"
                            value="john.wick@reeves.com"
                          />
                          <span class="form-text text-muted"
                            >Please enter your email address.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 1-->

              <!--begin: Form Wizard Step 2-->
              <div
                class="kt-wizard-v4__content"
                data-ktwizard-type="step-content"
              >
                <div class="kt-heading kt-heading--md">Setup Your Address</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <div class="form-group">
                      <label>Address Line 1</label>
                      <input
                        class="form-control"
                        name="address1"
                        placeholder="Address Line 1"
                        type="text"
                        value="Address Line 1"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Address.</span
                      >
                    </div>
                    <div class="form-group">
                      <label>Address Line 2</label>
                      <input
                        class="form-control"
                        name="address2"
                        placeholder="Address Line 2"
                        type="text"
                        value="Address Line 2"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Address.</span
                      >
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Postcode</label>
                          <input
                            class="form-control"
                            name="postcode"
                            placeholder="Postcode"
                            type="text"
                            value="3000"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Postcode.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>City</label>
                          <input
                            class="form-control"
                            name="state"
                            placeholder="City"
                            type="text"
                            value="Melbourne"
                          />
                          <span class="form-text text-muted"
                            >Please enter your City.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>State</label>
                          <input
                            class="form-control"
                            name="state"
                            placeholder="State"
                            type="text"
                            value="VIC"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Postcode.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Country:</label>
                          <select class="form-control" name="country">
                            <option value="">Select</option>
                            <option value="AF">Afghanistan</option>
                            <option value="AX">Åland Islands</option>
                            <option value="AL">Albania</option>
                            <option value="DZ">Algeria</option>
                            <option value="AS">American Samoa</option>
                            <option value="AD">Andorra</option>
                            <option value="AO">Angola</option>
                            <option value="AI">Anguilla</option>
                            <option value="AQ">Antarctica</option>
                            <option value="AG">Antigua and Barbuda</option>
                            <option value="AR">Argentina</option>
                            <option value="AM">Armenia</option>
                            <option value="AW">Aruba</option>
                            <option selected value="AU">Australia</option>
                            <option value="AT">Austria</option>
                            <option value="AZ">Azerbaijan</option>
                            <option value="BS">Bahamas</option>
                            <option value="BH">Bahrain</option>
                            <option value="BD">Bangladesh</option>
                            <option value="BB">Barbados</option>
                            <option value="BY">Belarus</option>
                            <option value="BE">Belgium</option>
                            <option value="BZ">Belize</option>
                            <option value="BJ">Benin</option>
                            <option value="BM">Bermuda</option>
                            <option value="BT">Bhutan</option>
                            <option value="BO"
                              >Bolivia, Plurinational State of
                            </option>
                            <option value="BQ"
                              >Bonaire, Sint Eustatius and Saba
                            </option>
                            <option value="BA">Bosnia and Herzegovina</option>
                            <option value="BW">Botswana</option>
                            <option value="BV">Bouvet Island</option>
                            <option value="BR">Brazil</option>
                            <option value="IO"
                              >British Indian Ocean Territory
                            </option>
                            <option value="BN">Brunei Darussalam</option>
                            <option value="BG">Bulgaria</option>
                            <option value="BF">Burkina Faso</option>
                            <option value="BI">Burundi</option>
                            <option value="KH">Cambodia</option>
                            <option value="CM">Cameroon</option>
                            <option value="CA">Canada</option>
                            <option value="CV">Cape Verde</option>
                            <option value="KY">Cayman Islands</option>
                            <option value="CF">Central African Republic</option>
                            <option value="TD">Chad</option>
                            <option value="CL">Chile</option>
                            <option value="CN">China</option>
                            <option value="CX">Christmas Island</option>
                            <option value="CC">Cocos (Keeling) Islands</option>
                            <option value="CO">Colombia</option>
                            <option value="KM">Comoros</option>
                            <option value="CG">Congo</option>
                            <option value="CD"
                              >Congo, the Democratic Republic of the
                            </option>
                            <option value="CK">Cook Islands</option>
                            <option value="CR">Costa Rica</option>
                            <option value="CI">Côte d'Ivoire</option>
                            <option value="HR">Croatia</option>
                            <option value="CU">Cuba</option>
                            <option value="CW">Curaçao</option>
                            <option value="CY">Cyprus</option>
                            <option value="CZ">Czech Republic</option>
                            <option value="DK">Denmark</option>
                            <option value="DJ">Djibouti</option>
                            <option value="DM">Dominica</option>
                            <option value="DO">Dominican Republic</option>
                            <option value="EC">Ecuador</option>
                            <option value="EG">Egypt</option>
                            <option value="SV">El Salvador</option>
                            <option value="GQ">Equatorial Guinea</option>
                            <option value="ER">Eritrea</option>
                            <option value="EE">Estonia</option>
                            <option value="ET">Ethiopia</option>
                            <option value="FK"
                              >Falkland Islands (Malvinas)
                            </option>
                            <option value="FO">Faroe Islands</option>
                            <option value="FJ">Fiji</option>
                            <option value="FI">Finland</option>
                            <option value="FR">France</option>
                            <option value="GF">French Guiana</option>
                            <option value="PF">French Polynesia</option>
                            <option value="TF"
                              >French Southern Territories
                            </option>
                            <option value="GA">Gabon</option>
                            <option value="GM">Gambia</option>
                            <option value="GE">Georgia</option>
                            <option value="DE">Germany</option>
                            <option value="GH">Ghana</option>
                            <option value="GI">Gibraltar</option>
                            <option value="GR">Greece</option>
                            <option value="GL">Greenland</option>
                            <option value="GD">Grenada</option>
                            <option value="GP">Guadeloupe</option>
                            <option value="GU">Guam</option>
                            <option value="GT">Guatemala</option>
                            <option value="GG">Guernsey</option>
                            <option value="GN">Guinea</option>
                            <option value="GW">Guinea-Bissau</option>
                            <option value="GY">Guyana</option>
                            <option value="HT">Haiti</option>
                            <option value="HM"
                              >Heard Island and McDonald Islands
                            </option>
                            <option value="VA"
                              >Holy See (Vatican City State)
                            </option>
                            <option value="HN">Honduras</option>
                            <option value="HK">Hong Kong</option>
                            <option value="HU">Hungary</option>
                            <option value="IS">Iceland</option>
                            <option value="IN">India</option>
                            <option value="ID">Indonesia</option>
                            <option value="IR"
                              >Iran, Islamic Republic of
                            </option>
                            <option value="IQ">Iraq</option>
                            <option value="IE">Ireland</option>
                            <option value="IM">Isle of Man</option>
                            <option value="IL">Israel</option>
                            <option value="IT">Italy</option>
                            <option value="JM">Jamaica</option>
                            <option value="JP">Japan</option>
                            <option value="JE">Jersey</option>
                            <option value="JO">Jordan</option>
                            <option value="KZ">Kazakhstan</option>
                            <option value="KE">Kenya</option>
                            <option value="KI">Kiribati</option>
                            <option value="KP"
                              >Korea, Democratic People's Republic of
                            </option>
                            <option value="KR">Korea, Republic of</option>
                            <option value="KW">Kuwait</option>
                            <option value="KG">Kyrgyzstan</option>
                            <option value="LA"
                              >Lao People's Democratic Republic
                            </option>
                            <option value="LV">Latvia</option>
                            <option value="LB">Lebanon</option>
                            <option value="LS">Lesotho</option>
                            <option value="LR">Liberia</option>
                            <option value="LY">Libya</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="LT">Lithuania</option>
                            <option value="LU">Luxembourg</option>
                            <option value="MO">Macao</option>
                            <option value="MK"
                              >Macedonia, the former Yugoslav Republic of
                            </option>
                            <option value="MG">Madagascar</option>
                            <option value="MW">Malawi</option>
                            <option value="MY">Malaysia</option>
                            <option value="MV">Maldives</option>
                            <option value="ML">Mali</option>
                            <option value="MT">Malta</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MQ">Martinique</option>
                            <option value="MR">Mauritania</option>
                            <option value="MU">Mauritius</option>
                            <option value="YT">Mayotte</option>
                            <option value="MX">Mexico</option>
                            <option value="FM"
                              >Micronesia, Federated States of
                            </option>
                            <option value="MD">Moldova, Republic of</option>
                            <option value="MC">Monaco</option>
                            <option value="MN">Mongolia</option>
                            <option value="ME">Montenegro</option>
                            <option value="MS">Montserrat</option>
                            <option value="MA">Morocco</option>
                            <option value="MZ">Mozambique</option>
                            <option value="MM">Myanmar</option>
                            <option value="NA">Namibia</option>
                            <option value="NR">Nauru</option>
                            <option value="NP">Nepal</option>
                            <option value="NL">Netherlands</option>
                            <option value="NC">New Caledonia</option>
                            <option value="NZ">New Zealand</option>
                            <option value="NI">Nicaragua</option>
                            <option value="NE">Niger</option>
                            <option value="NG">Nigeria</option>
                            <option value="NU">Niue</option>
                            <option value="NF">Norfolk Island</option>
                            <option value="MP">Northern Mariana Islands</option>
                            <option value="NO">Norway</option>
                            <option value="OM">Oman</option>
                            <option value="PK">Pakistan</option>
                            <option value="PW">Palau</option>
                            <option value="PS"
                              >Palestinian Territory, Occupied
                            </option>
                            <option value="PA">Panama</option>
                            <option value="PG">Papua New Guinea</option>
                            <option value="PY">Paraguay</option>
                            <option value="PE">Peru</option>
                            <option value="PH">Philippines</option>
                            <option value="PN">Pitcairn</option>
                            <option value="PL">Poland</option>
                            <option value="PT">Portugal</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="QA">Qatar</option>
                            <option value="RE">Réunion</option>
                            <option value="RO">Romania</option>
                            <option value="RU">Russian Federation</option>
                            <option value="RW">Rwanda</option>
                            <option value="BL">Saint Barthélemy</option>
                            <option value="SH"
                              >Saint Helena, Ascension and Tristan da Cunha
                            </option>
                            <option value="KN">Saint Kitts and Nevis</option>
                            <option value="LC">Saint Lucia</option>
                            <option value="MF"
                              >Saint Martin (French part)
                            </option>
                            <option value="PM"
                              >Saint Pierre and Miquelon
                            </option>
                            <option value="VC"
                              >Saint Vincent and the Grenadines
                            </option>
                            <option value="WS">Samoa</option>
                            <option value="SM">San Marino</option>
                            <option value="ST">Sao Tome and Principe</option>
                            <option value="SA">Saudi Arabia</option>
                            <option value="SN">Senegal</option>
                            <option value="RS">Serbia</option>
                            <option value="SC">Seychelles</option>
                            <option value="SL">Sierra Leone</option>
                            <option value="SG">Singapore</option>
                            <option value="SX"
                              >Sint Maarten (Dutch part)
                            </option>
                            <option value="SK">Slovakia</option>
                            <option value="SI">Slovenia</option>
                            <option value="SB">Solomon Islands</option>
                            <option value="SO">Somalia</option>
                            <option value="ZA">South Africa</option>
                            <option value="GS"
                              >South Georgia and the South Sandwich Islands
                            </option>
                            <option value="SS">South Sudan</option>
                            <option value="ES">Spain</option>
                            <option value="LK">Sri Lanka</option>
                            <option value="SD">Sudan</option>
                            <option value="SR">Suriname</option>
                            <option value="SJ">Svalbard and Jan Mayen</option>
                            <option value="SZ">Swaziland</option>
                            <option value="SE">Sweden</option>
                            <option value="CH">Switzerland</option>
                            <option value="SY">Syrian Arab Republic</option>
                            <option value="TW"
                              >Taiwan, Province of China
                            </option>
                            <option value="TJ">Tajikistan</option>
                            <option value="TZ"
                              >Tanzania, United Republic of
                            </option>
                            <option value="TH">Thailand</option>
                            <option value="TL">Timor-Leste</option>
                            <option value="TG">Togo</option>
                            <option value="TK">Tokelau</option>
                            <option value="TO">Tonga</option>
                            <option value="TT">Trinidad and Tobago</option>
                            <option value="TN">Tunisia</option>
                            <option value="TR">Turkey</option>
                            <option value="TM">Turkmenistan</option>
                            <option value="TC">Turks and Caicos Islands</option>
                            <option value="TV">Tuvalu</option>
                            <option value="UG">Uganda</option>
                            <option value="UA">Ukraine</option>
                            <option value="AE">United Arab Emirates</option>
                            <option value="GB">United Kingdom</option>
                            <option value="US">United States</option>
                            <option value="UM"
                              >United States Minor Outlying Islands
                            </option>
                            <option value="UY">Uruguay</option>
                            <option value="UZ">Uzbekistan</option>
                            <option value="VU">Vanuatu</option>
                            <option value="VE"
                              >Venezuela, Bolivarian Republic of
                            </option>
                            <option value="VN">Viet Nam</option>
                            <option value="VG">Virgin Islands, British</option>
                            <option value="VI">Virgin Islands, U.S.</option>
                            <option value="WF">Wallis and Futuna</option>
                            <option value="EH">Western Sahara</option>
                            <option value="YE">Yemen</option>
                            <option value="ZM">Zambia</option>
                            <option value="ZW">Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 2-->

              <!--begin: Form Wizard Step 3-->
              <div
                class="kt-wizard-v4__content"
                data-ktwizard-type="step-content"
              >
                <div class="kt-heading kt-heading--md">
                  Enter your Payment Details
                </div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <div class="row">
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Name on Card</label>
                          <input
                            class="form-control"
                            name="ccname"
                            placeholder="Card Name"
                            type="text"
                            value="John Wick"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card Name.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="form-group">
                          <label>Card Number</label>
                          <input
                            class="form-control"
                            name="ccnumber"
                            placeholder="Card Number"
                            type="text"
                            value="4444 3333 2222 1111"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Address.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="form-group">
                          <label>Card Expiry Month</label>
                          <input
                            class="form-control"
                            name="ccmonth"
                            placeholder="Card Expiry Month"
                            type="number"
                            value="01"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card Expiry Month.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <label>Card Expiry Year</label>
                          <input
                            class="form-control"
                            name="ccyear"
                            placeholder="Card Expire Year"
                            type="number"
                            value="21"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card Expiry Year.</span
                          >
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <label>Card CVV Number</label>
                          <input
                            class="form-control"
                            name="cccvv"
                            placeholder="Card CVV Number"
                            type="password"
                            value="123"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Card CVV Number.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 3-->

              <!--begin: Form Wizard Step 4-->
              <div
                class="kt-wizard-v4__content"
                data-ktwizard-type="step-content"
              >
                <div class="kt-heading kt-heading--md">
                  Review your Details and Submit
                </div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__review">
                    <div class="kt-wizard-v4__review-item">
                      <div class="kt-wizard-v4__review-title">
                        Your Account Details
                      </div>
                      <div class="kt-wizard-v4__review-content">
                        John Wick<br />
                        Phone: +61412345678<br />
                        Email: johnwick@reeves.com
                      </div>
                    </div>
                    <div class="kt-wizard-v4__review-item">
                      <div class="kt-wizard-v4__review-title">
                        Your Address Details
                      </div>
                      <div class="kt-wizard-v4__review-content">
                        Address Line 1<br />
                        Address Line 2<br />
                        Melbourne 3000, VIC, Australia
                      </div>
                    </div>
                    <div class="kt-wizard-v4__review-item">
                      <div class="kt-wizard-v4__review-title">
                        Payment Details
                      </div>
                      <div class="kt-wizard-v4__review-content">
                        Card Number: xxxx xxxx xxxx 1111<br />
                        Card Name: John Wick<br />
                        Card Expiry: 01/21
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 4-->

              <!--begin: Form Actions -->
              <div class="kt-form__actions">
                <button
                  class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                  data-ktwizard-type="action-prev"
                >
                  Previous
                </button>
                <button
                  class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                  data-ktwizard-type="action-submit"
                  v-on:click="submit"
                >
                  Submit
                </button>
                <button
                  class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                  data-ktwizard-type="action-next"
                >
                  Next Step
                </button>
              </div>
              <!--end: Form Actions -->
            </form>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTUtil from "@/assets/js/util";
import KTWizard from "@/assets/js/wizard";
import Swal from "sweetalert2";

export default {
  name: "Wizard-4",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard", route: "wizard-1" },
      { title: "Wizard-4" },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(function () {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
    },
  },
};
</script>
